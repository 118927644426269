import React, { useEffect, useState, useCallback } from "react";
import PageContainer from "../../layouts/PageContainer";

import { Grid } from "@material-ui/core";
import PlanList from "./PlanList";
import { helpers } from "../../helper/helper";
import Swal from "sweetalert2";
import { useSnackbar } from "notistack";

const Plan = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [planList, setPlanList] = useState();
  const [check, setCheck] = useState(true);
  const [open, setOpen] = React.useState(false);

  const handleClose = () => {
    setOpen(false);
  };

  const PlanData = useCallback(async () => {
    try {
      const url = "plans";
      const result = await helpers.apiCall("GET", url);
      setPlanList(
        result && result.data && result.data.result ? result.data.result : []
      );
    } catch (error) {
      setPlanList([]);
      console.error("error", error);
    }
  }, [setPlanList]);

  useEffect(() => {
    PlanData();
    setOpen(false);
  }, [PlanData]);

  const deletePlan = async (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#f50057",
      cancelButtonColor: "#263273",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.value) {
        Swal.fire("Deleted!", "Your file has been deleted.", "success");
        const url = `plans/${id}`;
        const result = await helpers.apiCall("DELETE", url);
        if (result.status === 200) {
          PlanData();
          handleClose();
          enqueueSnackbar("Plan Deleted successfully", {
            variant: "success",
            autoHideDuration: 3000,
          });
        } else {
          enqueueSnackbar(result.data ? result.data.message : result.message, {
            variant: "error",
            autoHideDuration: 3000,
          });
        }
      }
    });
  };

  // const handleChange = () => {
  //     setCheck(!check);
  // };

  return (
    <PageContainer pageheading="CREATE MEMBERS PLAN">
      <Grid container spacing={3}>
        {/* <Grid item sm={6} xs={12}>
                    <br/>
                    <br/>
                    <CreatePlan
                        PlanData={PlanData}
                        editCheck={editCheck}
                        singlePlan={singlePlan}
                        editPlan={editPlan}
                    />
                </Grid> */}
        <Grid item sm={12} xs={12}>
          {/* <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch color="primary" checked={check} onChange={handleChange} name="check"/>
                            }
                            label="Show Members Plan List"
                        />
                    </FormGroup> */}

          <PlanList
            planList={planList}
            deletePlan={deletePlan}
            setOpen={setOpen}
            open={open}
            handleClose={handleClose}
            // editPlan={editPlan}
            // singlePlanById={singlePlanById}
            check={check}
            setCheck={setCheck}
          />
        </Grid>
      </Grid>
    </PageContainer>
  );
};

export default Plan;
