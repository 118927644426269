import React, {useEffect, useState, useCallback} from "react";
import PageContainer from "../../layouts/PageContainer";

import {Grid} from "@material-ui/core";
import InviteList from "./InviteList";
import {helpers} from "../../helper/helper";

import {useSnackbar} from "notistack";

const Invite = () => {
    const {enqueueSnackbar} = useSnackbar();
    const [ChannelsList, setChannelsList] = useState();
    const [check, setCheck] = useState(false);
  

    const TaskData = useCallback(async () => {
        try {
            const url = "reward";
            const result = await helpers.apiCall("GET", url);
            setChannelsList(
                result && result.data && result.data.result ? result.data.result : []
            );
            console.log(result, 'result')
        } catch (error) {
            setChannelsList([]);
            console.error("error", error);
        }
    }, [setChannelsList]);

    useEffect(() => {
        TaskData();
    }, [TaskData]);


    const deleteGifts = async (id) => {
        const url = `tasks/${id}`;
        const result = await helpers.apiCall("DELETE", url);
        if (result.status === 200) {
            TaskData();
            enqueueSnackbar("Deleted successfully", {
                variant: 'success',
                autoHideDuration: 3000,
            });
        }
        else {
            enqueueSnackbar(result.data ? result.data.message : result.message, {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    };
    // const handleChange = () => {
    //     setCheck(!check);
    // };

    return (
        <PageContainer pageheading="INVITE AND EARN">
            <Grid container spacing={3}>
               
                <Grid item sm={12} xs={12}>
                    {/* <FormGroup row>
                        <FormControlLabel
                            control={
                                <Switch checked={check} onChange={handleChange} name="check" color="primary"/>
                            }
                            label="Show Tasks List"
                        />
                    </FormGroup> */}

                    <InviteList
                        channelsList={ChannelsList}
                        deleteGifts={deleteGifts}
                        setCheck={setCheck}
                        check={check}
                    />
                </Grid>
            </Grid>
        </PageContainer>
    );
};

export default Invite;
